import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'

import BannerElement from '../components/banner-element'
import UspPreviewFrontpage from '../components/usp-preview-frontpage'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    usps: allSanityUsp(
      limit: 3
      sort: { fields: [publishedAt], order: ASC}
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawBody(resolveReferences: {maxDepth: 5})
        }
      }
    }
    banner: allSanityBanner(
      sort: { fields: [_createdAt], order: ASC}
    ) {
      edges {
        node {
          _id
          pretitle
          title
          subtitle
          mainImage {
            ...SanityImage
            alt
          }
          primaryButton {
            title
            reference {
              slug {
                current
              }
            }
          }
          secondaryButton {
            title
            reference {
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const bannerNodes = data && data.banner && mapEdgesToNodes(data.banner)
  const uspNodes = data && data.usps && mapEdgesToNodes(data.usps)
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      {bannerNodes && bannerNodes.length > 0 && <BannerElement nodes={bannerNodes} />}
      {uspNodes && uspNodes.length > 0 && <UspPreviewFrontpage nodes={uspNodes} />}
      <Container>
        {postNodes && (
          <BlogPostPreviewList
            nodes={postNodes}
            browseMoreHref='/archive/'
          />
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
