import {Link} from 'gatsby'
import React from 'react'
import UspPreview from './usp-preview'

import styles from './usp-preview-frontpage.module.css'


function UspPreviewFrontpage (props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <UspPreview {...node} />
            </li>
          ))}
      </ul>
    </div>
  )
}

UspPreviewFrontpage.defaultProps = {
  title: '',
  nodes: []
}

export default UspPreviewFrontpage
