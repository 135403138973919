import {format, distanceInWords, differenceInDays} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, cn} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './banner-element.module.css'
import {responsiveTitle3} from './typography.module.css'
function BannerElement (props) {
  const {pretitle, title, subtitle, primaryButton, secondaryButton} = props

  return (
    <div className={styles.root}>
      {props.nodes.map(props => (
      <div key={props} className={styles.grid}>
        <div className={styles.content}>
          <h3 className={cn(responsiveTitle3, styles.pretitle)}>{props.pretitle}</h3>
          <h1 className={cn(responsiveTitle3, styles.title)}>{props.title}</h1>
          <h2 className={cn(responsiveTitle3, styles.subtitle)}>{props.subtitle}</h2>
          <div className={styles.actions}>
              <a className={styles.primaryButton} href={props.primaryButton.reference.slug.current}>{props.primaryButton.title}</a>
              <a className={styles.secondaryButton} href={props.secondaryButton.reference.slug.current}>{props.secondaryButton.title}</a>
          </div>

        </div>
        <div className={styles.image}>
          {props.mainImage && props.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(600)
                .height(Math.floor((9 / 16) * 600))
                .auto('format')
                .url()}
              alt={props.mainImage.alt}
            />
          )}
        </div>
      </div>
    ))}
    </div>
  )
}

BannerElement.defaultProps = {
  title: '',
  nodes: []
}

export default BannerElement
